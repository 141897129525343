"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var rio_common_1 = require("@srgssr/rio-common");
var youboraPlugin;
var LOG_TAG = 'RioApp';
var HEARTBEAT_PERIOD = 30000;
var castContext = cast.framework.CastReceiverContext.getInstance();
var castDebugLogger = cast.debug.CastDebugLogger.getInstance();
var hostname = window.location.hostname;
var debugMode = hostname.includes('ngrok') || hostname.includes('dev.') || hostname.includes('localhost');
castContext.addEventListener(cast.framework.system.EventType.READY, function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line no-underscore-dangle
    if (!castDebugLogger.debugOverlayElement_) {
        // Enable debug logger and show a 'DEBUG MODE' overlay at top left corner.
        castDebugLogger.setEnabled(debugMode);
        // Show debug overlay
        castDebugLogger.showDebugLogs(true);
        // Clear log messages on debug overlay
        castDebugLogger.clearDebugLogs();
    }
});
castDebugLogger.loggerLevelByEvents = {
// 'cast.framework.events.category.CORE': cast.framework.LoggerLevel.INFO,
// 'cast.framework.events.EventType.MEDIA_STATUS': cast.framework.LoggerLevel.DEBUG
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var log = function () {
    var vars = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        vars[_i] = arguments[_i];
    }
    castDebugLogger.info.apply(castDebugLogger, __spreadArray([LOG_TAG, 'DEBUG'], __read(vars), false));
};
/* eslint-disable no-param-reassign */
var playerManager = castContext.getPlayerManager();
playerManager.setMessageInterceptor(cast.framework.messages.MessageType.LOAD, function (loadRequestData) {
    var _a;
    loadRequestData.media.hlsSegmentFormat = cast.framework.messages.HlsSegmentFormat.TS;
    var customData = (_a = loadRequestData.media) === null || _a === void 0 ? void 0 : _a.customData;
    if (customData) {
        var stats = customData.stats, asset = customData.asset, userId = customData.userId;
        if (stats && asset) {
            var context = loadRequestData.media.customData.stats.context;
            customData.statsEventSender = new rio_common_1.StatsEventSender(context, rio_common_1.ENV_VARS.eventGatewayUrl, rio_common_1.ENV_VARS.eventGatewaySubscriptionKey);
        }
        if (asset && userId) {
            youboraPlugin === null || youboraPlugin === void 0 ? void 0 : youboraPlugin.setOptions((0, rio_common_1.createNpawWebMetadata)({
                asset: asset,
                appName: 'googlecast-receiver',
                userId: userId,
            }));
        }
    }
    return loadRequestData;
});
/* eslint-enable no-param-reassign */
var CUSTOM_CHANNEL = 'urn:x-cast:ch.srgssr.playsuisse';
castContext.addCustomMessageListener(CUSTOM_CHANNEL, function (event) {
    var _a;
    if ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.language) {
        updateSplashLanguage(event.data.language);
    }
});
var getLanguages = function () {
    var _a, _b;
    try {
        var audioLanguage = (_a = playerManager.getAudioTracksManager().getActiveTrack()) === null || _a === void 0 ? void 0 : _a.language;
        var subtitleLanguage = (_b = playerManager.getTextTracksManager().getActiveTracks()[0]) === null || _b === void 0 ? void 0 : _b.language;
        return { audioLanguage: audioLanguage, subtitleLanguage: subtitleLanguage };
    }
    catch (e) {
        return {};
    }
};
var getCustomData = function () { var _a; return (_a = playerManager.getMediaInformation()) === null || _a === void 0 ? void 0 : _a.customData; };
var sendEvent = function (type) {
    var _a, _b;
    var customData = getCustomData();
    if (!customData)
        return;
    var statsEventSender = customData.statsEventSender, asset = customData.asset;
    if (!statsEventSender || !asset)
        return;
    var _c = getLanguages(), audioLanguage = _c.audioLanguage, subtitleLanguage = _c.subtitleLanguage;
    var clips = (0, rio_common_1.getStreamClips)({ position: playerManager.getCurrentTimeSec(), duration: playerManager.getDurationSec() }, (_a = asset.streamMetadata) === null || _a === void 0 ? void 0 : _a.clipDurations);
    var position = clips.active === 'main' ? clips.main.position : 0;
    var statsMedia = new rio_common_1.StatsMediaEventMedia({
        position: Math.round(position),
        audioLanguage: audioLanguage !== null && audioLanguage !== void 0 ? audioLanguage : null,
        subtitleLanguage: subtitleLanguage !== null && subtitleLanguage !== void 0 ? subtitleLanguage : null,
    });
    var statsAsset = new rio_common_1.StatsMediaEventAsset({ id: asset.id });
    var statsEvent = new rio_common_1.StatsMediaEvent({
        type: type,
        asset: statsAsset,
        collection: (_b = customData === null || customData === void 0 ? void 0 : customData.stats) === null || _b === void 0 ? void 0 : _b.collection,
        media: statsMedia,
        mode: 'googlecast',
    });
    statsEventSender.push(statsEvent);
    statsEventSender.flush();
};
playerManager.addEventListener(cast.framework.events.EventType.LOADED_DATA, function () {
    updateNpawWithTracks();
});
var progressTimeout = null;
var progressHandler = function () {
    if (progressTimeout)
        clearTimeout(progressTimeout);
    if (playerManager.getPlayerState() === cast.framework.messages.PlayerState.PLAYING) {
        sendEvent(rio_common_1.StatsMediaEventType.MediaProgressed);
        progressTimeout = setTimeout(progressHandler, HEARTBEAT_PERIOD);
    }
    updateNpawWithTracks(); // Subtitle language does not seem to be set on LOADED_DATA event
};
playerManager.addEventListener(cast.framework.events.EventType.PLAY, function () {
    var customData = getCustomData();
    if (!customData)
        return;
    if (progressTimeout)
        clearTimeout(progressTimeout);
    progressTimeout = setTimeout(progressHandler, HEARTBEAT_PERIOD);
    if (!customData.status || customData.status === 'loaded') {
        sendEvent(rio_common_1.StatsMediaEventType.MediaStarted);
        customData.status = 'started';
        return;
    }
    sendEvent(rio_common_1.StatsMediaEventType.MediaResumed);
});
playerManager.addEventListener(cast.framework.events.EventType.PAUSE, function () {
    sendEvent(rio_common_1.StatsMediaEventType.MediaPaused);
});
playerManager.addEventListener(cast.framework.events.EventType.SEEKED, function () {
    sendEvent(rio_common_1.StatsMediaEventType.MediaSeeked);
});
playerManager.addEventListener(cast.framework.events.EventType.ENDED, function () {
    sendEvent(rio_common_1.StatsMediaEventType.MediaCompleted);
});
var updateSplashLanguage = function (lang) {
    var playerElement = document.getElementsByTagName('cast-media-player')[0];
    playerElement.style.setProperty('--splash-image', "url(\"/res/splash-".concat(lang, ".png\")"));
};
var updateNpawWithTracks = function () {
    var _a = getLanguages(), audioLanguage = _a.audioLanguage, subtitleLanguage = _a.subtitleLanguage;
    youboraPlugin.setOptions({
        'content.language': audioLanguage,
        'content.subtitles': subtitleLanguage,
    });
};
// change splash language according to chromecast language
var lang = navigator.language.split('-')[0];
if (['de', 'fr', 'it'].includes(lang)) {
    updateSplashLanguage(lang);
}
if (typeof youbora !== 'undefined') {
    if (debugMode) {
        youbora.Log.logLevel = youbora.Log.Level.DEBUG;
    }
    youboraPlugin = new youbora.Plugin({
        accountCode: debugMode ? 'playsuissedev' : 'playsuisse',
    });
    youboraPlugin.setAdapter(new youbora.adapters.ChromecastCAF(playerManager));
    // For easier debugging
    window.youboraPlugin = youboraPlugin;
}
castContext.start();
